exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-and-ml-js": () => import("./../../../src/pages/ai-and-ml.js" /* webpackChunkName: "component---src-pages-ai-and-ml-js" */),
  "component---src-pages-ai-posts-js": () => import("./../../../src/pages/ai-posts.js" /* webpackChunkName: "component---src-pages-ai-posts-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-branding-posts-js": () => import("./../../../src/pages/branding-posts.js" /* webpackChunkName: "component---src-pages-branding-posts-js" */),
  "component---src-pages-engineering-posts-js": () => import("./../../../src/pages/engineering-posts.js" /* webpackChunkName: "component---src-pages-engineering-posts-js" */),
  "component---src-pages-gadgets-posts-js": () => import("./../../../src/pages/gadgets-posts.js" /* webpackChunkName: "component---src-pages-gadgets-posts-js" */),
  "component---src-pages-games-posts-js": () => import("./../../../src/pages/games-posts.js" /* webpackChunkName: "component---src-pages-games-posts-js" */),
  "component---src-pages-graphic-design-js": () => import("./../../../src/pages/graphic-design.js" /* webpackChunkName: "component---src-pages-graphic-design-js" */),
  "component---src-pages-graphic-design-posts-js": () => import("./../../../src/pages/graphic-design-posts.js" /* webpackChunkName: "component---src-pages-graphic-design-posts-js" */),
  "component---src-pages-ideachat-ai-js": () => import("./../../../src/pages/ideachat-ai.js" /* webpackChunkName: "component---src-pages-ideachat-ai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-learning-posts-js": () => import("./../../../src/pages/machine-learning-posts.js" /* webpackChunkName: "component---src-pages-machine-learning-posts-js" */),
  "component---src-pages-mobile-app-posts-js": () => import("./../../../src/pages/mobile-app-posts.js" /* webpackChunkName: "component---src-pages-mobile-app-posts-js" */),
  "component---src-pages-neurox-js": () => import("./../../../src/pages/neurox.js" /* webpackChunkName: "component---src-pages-neurox-js" */),
  "component---src-pages-privacy-page-js": () => import("./../../../src/pages/privacy-page.js" /* webpackChunkName: "component---src-pages-privacy-page-js" */),
  "component---src-pages-seo-posts-js": () => import("./../../../src/pages/seo-posts.js" /* webpackChunkName: "component---src-pages-seo-posts-js" */),
  "component---src-pages-social-media-marketing-posts-js": () => import("./../../../src/pages/social-media-marketing-posts.js" /* webpackChunkName: "component---src-pages-social-media-marketing-posts-js" */),
  "component---src-pages-software-quality-assurance-js": () => import("./../../../src/pages/software-quality-assurance.js" /* webpackChunkName: "component---src-pages-software-quality-assurance-js" */),
  "component---src-pages-technology-posts-js": () => import("./../../../src/pages/technology-posts.js" /* webpackChunkName: "component---src-pages-technology-posts-js" */),
  "component---src-pages-testing-posts-js": () => import("./../../../src/pages/testing-posts.js" /* webpackChunkName: "component---src-pages-testing-posts-js" */),
  "component---src-pages-ui-ux-js": () => import("./../../../src/pages/ui-ux.js" /* webpackChunkName: "component---src-pages-ui-ux-js" */),
  "component---src-pages-ui-ux-posts-js": () => import("./../../../src/pages/ui-ux-posts.js" /* webpackChunkName: "component---src-pages-ui-ux-posts-js" */),
  "component---src-pages-web-dev-js": () => import("./../../../src/pages/web-dev.js" /* webpackChunkName: "component---src-pages-web-dev-js" */),
  "component---src-pages-web-dev-posts-js": () => import("./../../../src/pages/web-dev-posts.js" /* webpackChunkName: "component---src-pages-web-dev-posts-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

